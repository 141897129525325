<template>
  <ul class="TabsUI">
    <li
      v-for="tab in filteredTabs"
      :key="tab.id"
      class="TabsUI__item"
      :class="getItemClass(tab.id)"
      @click="$emit('onTabClick', tab.id)"
    >{{ tab.title }}</li>
    <div class="TabsUI__slider" ref="slider"></div>
  </ul>
</template>

<script>
export default {
  name: 'TabsUI',

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTabId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    // Отсортированные вкладки
    filteredTabs() {
      return this.tabs.filter(tab => tab.show === true);
    },
  },
  watch: {
    // При смене вкладки докручиваем до нее
    activeTabId() {
      this.$nextTick(this.scrollToActiveTab);
    },

    // При смене вкладки докручиваем до нее
    filteredTabs() {
      this.$nextTick(this.scrollToActiveTab);
    },
  },

  mounted() {
    this.scrollToActiveTab();
  },

  methods: {
    // Получение класс для элемента
    getItemClass(id) {
      return {
        'TabsUI__item--active': this.activeTabId === id
      };
    },

    // Прокрутка до активной вкладки
    scrollToActiveTab() {
      const tabs = [...this.$el.children].filter(child => child.classList.contains('TabsUI__item'));

      const activeTab = tabs.find(tab => tab.classList.contains('TabsUI__item--active'));

      if (!activeTab) {
        return;
      }

      const activeTabWidth = activeTab.offsetWidth;
      const activeTabOffsetLeft = activeTab.offsetLeft;

      const slider = this.$refs.slider;

      slider.style.width = `${activeTabWidth}px`;
      slider.style.transform = `translateX(${activeTabOffsetLeft}px)`;
    },
  },
}
</script>

<style lang="scss">
.dark-theme {
  .TabsUI {
    border-bottom-color: $darkThemeBgTwo;

    &__item {
      color: $darkThemeText;

      &:hover::before {
        background-color: $darkThemeText;
      }

      &--active {
        color: #9EA5F7;
      }
    }

    &__slider {
      background-color: #9EA5F7;
    }
  }
}

.TabsUI {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 0 15px;
  position: relative;
  margin: 0;
  padding: 0 20px;
  border-bottom: 1px solid $borderColor;
  list-style: none;

  // Вкладка
  &__item {
    position: relative;
    padding: 11px 6px;
    color: $lightThemeText;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;

      &::before {
        background-color: #B8B8B8;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      transition: all 0.2s;
    }

    &--active {
      pointer-events: none;
    }
  }

  // Полоска активной вкладки
  &__slider {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    height: 2px;
    background-color: $blue;
    transition: all 0.4s;
  }
}
</style>
